<template>
    <v-autocomplete v-model="selectedPartners" ref="inputPartners" :items="displayPartners" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="resetSearch()" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedPartners)" :multiple="multiple" :label="label" :placeholder="placeholder" no-data-text="Commencez à taper pour lancer une recherche" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                {{ data.item.name }}
                <small class="ml-1" v-if="data.item.company">
                    <i>
                        ({{ data.item.company }})
                    </i>
                </small>
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedPartners.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                    <small v-if="item.company">
                        <i>
                            ({{ item.company }})
                        </i>
                    </small>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'PartnersAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        initPartners: { type: Array }
    },

    data: () => ({
        selectedPartners: null,
        partners: [],
        displayPartners: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        value() {
            this.selectedPartners = this.value;
        },

        selectedPartners() {
            // this.$refs.inputPartners.focus();
            this.sortPartners();
        },

        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayPartners = this.partners;
                } else {
                    this.displayPartners = this.partners.filter((p) => {
                        return (p.name && p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                            || (p.company && p.company.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
                    });
                }
                this.sortPartners();
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchPartners(query);
            }, 500);
        },

        initPartners() {
            this.displayPartners = this.initPartners;
            this.sortPartners();
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun partenaire disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchPartners(query) {
            try {
                this.isLoading = true;
                const { partners } = await this.repos.partners.getPartners(query);
                this.partners = partners;
                this.displayPartners = partners;
                this.sortPartners();
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.selectedPartners = [];
        },

        resetSearch() {
            this.search = null;
            this.displayPartners = this.partners;
            this.sortPartners();
        },

        sortPartners() {
            this.displayPartners.sort((a, b) => {
                const isASelected = this.selectedPartners.find((p) => p === a.id);
                const isBSelected = this.selectedPartners.find((p) => p === b.id);
                if (isASelected && !isBSelected) {
                    return -1;
                } else if (isBSelected && !isASelected) {
                    return 1;
                } else {
                    return a.name.localeCompare(b.name);
                }
            });
        }
    },

    created() {
        this.selectedPartners = this.value;
        if ((Array.isArray(this.selectedPartners) && this.selectedPartners.length > 0) || typeof this.selectedPartners === 'number') {
            const partners = Array.isArray(this.selectedPartners) ? this.selectedPartners : [this.selectedPartners];
            this.fetchPartners({ partners });
        }
        if (this.initPartners) {
            this.displayPartners = this.initPartners;
            this.sortPartners();
        }
    }
};
</script>
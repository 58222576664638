export default {
    data: () => ({
        actualitiesContexts: [
            { id: 'partners', label: 'Générique' },
            { id: 'partners-programs', label: 'Programme(s)' },
            { id: 'partners-programs-groups', label: 'Groupe(s) de programmes' },
            { id: 'partners-partners', label: 'Partenaire(s)' },
            { id: 'partners-partners-groups', label: 'Groupe(s) de partenaires' }
        ]
    }),

    methods: {
        getActualityContext(id, key) {
            const item = this.actualitiesContexts.find((o) => o.id === id);
            if (item) {
                return item[key];
            } else {
                console.warn(`Unknown actuality context: "${id}"`);
                return '';
            }
        },

        getActualityAttachmentImages(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'image');
            } else {
                return [];
            }
        },

        getActualityAttachmentYoutubeLinks(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'link' && a.targetFileName.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/));
            } else {
                return [];
            }
        },

        getActualityAttachmentVideoLinks(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'link' && a.targetFileName.match(/(http:|https:)?\/\/.*?\.mp4/));
            } else {
                return [];
            }
        },

        getActualityAttachmentDocuments(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'document');
            } else {
                return [];
            }
        },

        getAttachmentUrl(attachment) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/actualities/attachments/${attachment.id}`;
        },

        getYoutubeEmbedUrl(url) {
            const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const matches = regex.exec(url);
            const videoId = matches[7];
            return `https://www.youtube.com/embed/${videoId}`;
        }
    }
};
